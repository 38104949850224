import { HIDDEN_SECTIONS, ProblemType } from 'constants/problem';
import { i18n } from 'libs/i18n';
import { Application } from 'types/application';
import { getByPath } from 'utils';
import { formatAddress } from 'utils/address';

import type { MessageDescriptor } from '@lingui/core';
import type { RegisteredAddress } from 'types/address';
import type { ApplicantInfo, IncomeEmployment } from 'types/applicant';
import type {
    ApplicationProblem,
    ApplicationProblemAugmented,
    ApplicationProblemsGrouped,
    ApplicationProblemsGroupedWithApplicants,
} from 'types/problems';
import type { OtherProperty, SubjectProperty } from 'types/property';

// Do not use `g` global flag because JS regex will use `lastIndex` from previous match
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#finding_successive_matches
const regexes: Record<ProblemType, RegExp> = {
    flags: /flags?\.?(.*)/i,
    product: /application\.product?\.?(.*)/i,
    downPayment: /application\.downPayment?\.?(.*)/i,
    mortgageDetails: /application\.mortgage?\.?(.*)/i,
    helocDetails: /application\.heloc?\.?(.*)/i,
    subjectProperty: /application\.property?\.?(.*)/i,
    incomeOthers:
        /application\.applicants\.(\d*)\.income\.others?\.?\[?(\d*)?\]?\.?(.*)/i,
    employments:
        /application\.applicants\.(\d*)\.income\.employments?\.?\[?(\d*)?\]?\.?(.*)/i,
    registeredAddresses:
        /application\.applicants\.(\d*)\.addresses?\.?\[?(\d*)?\]?\.?(.*)/i,
    assets: /application\.applicants\.(\d*)\.allAssets?\.?\[?(\d*)?\]?\.?(.*)/i,
    // Applicants - everything except addresses|income|properties|allAssets|liabilities
    // these exceptions are handled with the other regexes
    applicants:
        /application\.applicants\.(\d*)?\.((?!addresses|income|properties|allAssets|liabilities).*)/i,
    properties:
        /application\.applicants\.(\d*)\.properties?\.?\[?(\d*)?\]?\.?(.*)/i,
    liabilities:
        /application\.applicants\.(\d*)\.liabilities?\.?\[?(\d*)?\]?\.?(.*)/i,
    application: /application\.?(.*)/i, // This needs to be last to avoid matching other sections
};

export const getRegexMatch = (
    problemSource: string
): {
    section: ProblemType;
    match: RegExpExecArray;
} => {
    for (const [sct, rgx] of Object.entries(regexes)) {
        const section: ProblemType = sct as ProblemType;
        const match = rgx.exec(problemSource);
        if (match) {
            return {
                section,
                match,
            };
        }
    }

    return {
        // TBD til we have all sections covered
        section: 'TODOS' as ProblemType,
        match: undefined,
    };
};

export const getApplicantIdFromMatch = (
    section: ProblemType,
    match: RegExpExecArray
) => {
    switch (section) {
        case ProblemType.applicants:
        case ProblemType.assets:
        case ProblemType.employments:
        case ProblemType.incomeOthers:
        case ProblemType.properties:
        case ProblemType.registeredAddresses:
        case ProblemType.liabilities:
            return parseInt(match?.[1], 10);
        default:
            return null;
    }
};

export const getAugmentedApplicationProblem = (
    problem: ApplicationProblem,
    application: Application
): ApplicationProblemAugmented => {
    const { section, match } = getRegexMatch(problem.source);
    const applicantId = getApplicantIdFromMatch(section, match);
    const entityPath = getEntityPath(section, match);
    const entity = entityPath ? getByPath(application, entityPath) : null;
    const fieldName = getFieldName(section, match);
    const arrayIndex = getArrayIndexFromEntityPath(entityPath);

    return {
        applicantId: applicantId || 0,
        section,
        entityPath,
        entity,
        entityId: entity?.id || 0,
        value: getByPath(application, getValueByPath(problem.source)),
        field: {
            name: fieldName,
            tx: getI18nMessageDescriptor(
                section,
                fieldName,
                entity,
                arrayIndex
            ),
        },
        ...problem,
    };
};

export const getVisibleProblemSectionsWithCount = (
    groupedProblems: ApplicationProblemsGrouped
) => {
    return Object.entries(groupedProblems || {})
        .filter(([section]) => !HIDDEN_SECTIONS.includes(section))
        .map(([section, problemsBySection]) => ({
            section: ProblemType[section],
            totalProblems: Array.isArray(problemsBySection)
                ? problemsBySection.length
                : Object.values(problemsBySection || {}).reduce(
                      (sum, problems) => sum + (problems?.length || 0),
                      0
                  ),
        }));
};

export const getEntityPath = (
    section: ProblemType,
    match: RegExpExecArray
): string => {
    const appStrLength = 'application.'.length;

    switch (section) {
        case ProblemType.applicants:
            return match?.input
                .slice(appStrLength, -match?.[2]?.length ?? 0)
                .replace(/\.$/, '');
        case ProblemType.assets:
        case ProblemType.employments:
        case ProblemType.incomeOthers:
        case ProblemType.properties:
        case ProblemType.registeredAddresses:
        case ProblemType.liabilities:
            return match?.input
                .slice(appStrLength, -match?.[3]?.length ?? 0)
                .replace(/\.$/, '');
        default:
            return match?.input
                .slice(appStrLength, -match?.[1]?.length ?? 0)
                .replace(/\.$/, '');
    }
};

export const getArrayIndexFromEntityPath = (entityPath: string) => {
    const match = entityPath?.match(/\[(\d*)\]/);
    return match ? parseInt(match[1], 10) : null;
};

export const getValueByPath = (source: string): string =>
    // Remove first `application.` in the source string
    // BE returns all source with prefix `application.`
    source.split('.').slice(1).join('.');

export const getFieldName = (
    section: ProblemType,
    match: RegExpExecArray
): string => {
    switch (section) {
        case ProblemType.applicants:
            return match?.[2];
        case ProblemType.assets:
        case ProblemType.employments:
        case ProblemType.incomeOthers:
        case ProblemType.properties:
        case ProblemType.registeredAddresses:
        case ProblemType.liabilities:
            return match?.[3];

        default:
            return match?.[1] || match?.[0];
    }
};

export const getI18nMessageDescriptor = (
    section: ProblemType,
    fieldName: string,
    entity: ApplicationProblem,
    arrayIndex: number | null = null
): MessageDescriptor => {
    return {
        id: entity
            ? `validation.problems.${section}.withEntity`
            : `validation.problems.${section}.noEntity`,
        values: {
            // Normal human readable index
            // https://makeameme.org/meme/array-index-start
            arrayIndex: arrayIndex + 1,
            count: fieldName?.length || 0,
            fieldName: fieldName && i18n._(getFieldNameTxId(fieldName)),
            ...getsectionTxValues(section, entity),
        },
    };
};

const getsectionTxValues = (section: ProblemType, entity: any) => {
    if (!entity) {
        return {};
    }

    switch (section) {
        case ProblemType.applicants:
            return getApplicantTxValues(entity);
        case ProblemType.employments:
            return getEmploymentTxValues(entity);
        case ProblemType.subjectProperty:
            return getSubjectPropertyTxValues(entity);
        case ProblemType.registeredAddresses:
            return getRegisteredAddressesTxValues(entity);
        case ProblemType.properties:
            return getPropertiesTxValues(entity);
        default:
            return {};
    }
};

const getApplicantTxValues = (entity: ApplicantInfo) => {
    return { firstName: entity.firstName, lastName: entity.lastName };
};

const getEmploymentTxValues = (entity: IncomeEmployment) => {
    return {
        employerName: entity.employer.name,
        employerNameLength: entity?.employer?.name?.length,
    };
};

const getSubjectPropertyTxValues = (entity: SubjectProperty) => {
    return {
        isFound: entity.isFound,
        formattedAddress: entity.isFound
            ? formatAddress(entity?.address)
            : undefined,
    };
};

const getRegisteredAddressesTxValues = (entity: RegisteredAddress) => {
    return {
        formattedAddress: formatAddress(entity?.address),
    };
};

const getPropertiesTxValues = (entity: OtherProperty) => {
    return {
        formattedAddress: formatAddress(entity?.address),
    };
};

const getFieldNameTxId = (fieldName?: string): string => {
    // some fields like the subject property mortgages section are not into another sections
    // fieldName will be an array of mortage `mortagage[n].interestRate`
    // the condition will take only the last segment of the string after `[n]` and remmoved the prefix `.`
    if (fieldName.includes(']')) {
        fieldName = fieldName.split(']')?.[1]?.replace(/^\./, '') || fieldName;
    }

    return (
        // Let's ignore formatting here to make it easier to read
        // prettier-ignore
        {
            //
            // Application
            //
            insurerName: 'insurerName',
            //
            // Applicant Info
            //
            applicantId: 'applicantId',
            dateOfBirth: 'Date Of Birth',
            salutation: 'salutation',
            firstName: 'First Name',
            lastName: 'Last Name',
            phone: 'Phone',
            maritalStatus: 'Marital Status',
            email: 'email',
            relationToMainApplicant: 'relationToMainApplicant',
            creditScoreQuality: 'creditScoreQuality',
            hasConsumerProposalOrBankruptcyLast5yrs: 'hasConsumerProposalOrBankruptcyLast5yrs',
            primaryBankingInstitution: 'primaryBank',
            primaryBankingInstitutionOther: 'institutionOther',
            covid19Impacted: 'covid19Impacted',
            covid19ImpactDescription: 'description',
            //
            // Applicant servicing info
            //
            financialInstitutionBankID: 'financialInstitutionBankID',
            financialInstitutionAccountNumber: 'financialInstitutionAccountNumber',
            financialInstitutionTransitNumber: 'financialInstitutionTransitNumber',
            //
            // Mortgage Details
            //
            rateOverride: 'rateOverride',
            purposeDescription: 'purposeDescription',
            closingDate: 'closingDate',
            financialWaiverDate: 'financialWaiverDate',
            interestAdjustedDate: 'interestAdjustedDate',
            compoundPeriod: 'compoundPeriod',
            paymentFrequency: 'paymentFrequency',
            amortizationYears: 'amortizationYears',
            amortizationMonths: 'amortizationMonths',
            //
            // Assets
            //
            name: 'name',
            description: 'description',
            make: 'make',
            model: 'model',
            year: 'year',
            donorFirstName: 'donorFirstName',
            donorLastName: 'donorLastName',
            familyRelationship: 'familyRelationship',
            institution: 'institution',
            institutionOther: 'institutionOther',
            existingPropertyId: 'existingPropertyId',
            totalValue: 'totalValue',
            amountUsedForDownPayment: 'amountUsedForDownPayment',
            type: 'type',
            // type: 'type',
            //
            // Subject Property servicing
            //
            estimatedPropertyValueMethod: 'estimatedPropertyValueMethod',
            environmentalHazard: 'environmentalHazard',
            previousSaleClosingDate: 'previousSaleClosingDate',
            previousSalePrice: 'previousSalePrice',
            propertyListingType: 'propertyListingType',
            improvementsMadeToProperty: 'improvementsMadeToProperty',
            improvedPropertyValue: 'improvedPropertyValue',
            //
            // Employer details
            //
            incomeType: 'incomeType',
            hasGuaranteedHours: 'guaranteedHours',
            isCurrent: 'currentEmployer',
            tenure: 'tenure',
            employmentType: 'employmentType',
            //
            // employer detail
            'employer.name': 'employer.name',
            'employer.phone': 'employer.phone',
            //
            //
            // Employment modal
            //
            // Income details INCOME OVERRIDE
            'incomeOverride.amount': 'incomeOverride.amount',
            'incomeOverride.frequency': 'incomeOverride.frequency',
            incomeOverrideIncluded: 'incomeOverrideIncluded',
            // Income details CURRENT YEAR
            //
            'salary.base.amount': 'salary.base.amount',
            'salary.base.frequency': 'salary.base.frequency',
            'salary.bonus.amount': 'salary.bonus.amount',
            'salary.bonus.frequency': 'salary.bonus.frequency',
            'salary.overtime.amount': 'salary.overtime.amount',
            'salary.overtime.frequency': 'salary.overtime.frequency',
            'salary.commission.amount': 'salary.commission.amount',
            'salary.commission.frequency': 'salary.commission.frequency',
            //
            // Income details PREVIOUS YEAR
            //
            'salaryPreviousYear.base.amount': 'salaryPreviousYear.base.amount',
            'salaryPreviousYear.base.frequency': 'salaryPreviousYear.base.frequency',
            'salaryPreviousYear.bonus.amount': 'salaryPreviousYear.bonus.amount',
            'salaryPreviousYear.bonus.frequency': 'salaryPreviousYear.bonus.frequency',
            'salaryPreviousYear.overtime.amount': 'salaryPreviousYear.overtime.amount',
            'salaryPreviousYear.overtime.frequency': 'salaryPreviousYear.overtime.frequency',
            'salaryPreviousYear.commission.amount': 'salaryPreviousYear.commission.amount',
            'salaryPreviousYear.commission.frequency': 'salaryPreviousYear.commission.frequency',
            //
            // Industry details
            //
            jobTitle: 'jobTitle',
            industry: 'industry',
            employedYears: 'employedYears',
            employedMonths: 'employedMonths',
            yearsInIndustry: 'yearsInIndustry',
            monthsInIndustry: 'monthsInIndustry',
            //
            // No Income details
            //
            // Pension details
            //
            pensionType: 'pensionType',
            //
            // Self employed details
            //
            'selfEmployed.operatingAs': 'operatingAs',
            //
            // Self Employed income
            //
            'selfEmployed.grossRevenue.amount': 'selfEmployed.grossRevenue.amount',
            'selfEmployed.grossRevenue.frequency': 'selfEmployed.grossRevenue.frequency',
            'selfEmployed.grossRevenuePreviousYear.amount': 'selfEmployed.grossRevenuePreviousYear.amount',
            'selfEmployed.grossRevenuePreviousYear.frequency': 'selfEmployed.grossRevenuePreviousYear.frequency',
            //
            // Self Employed industry detail
            //
            // Liabilities
            //
            included: 'included',
            coOwnerApplicantIds: 'coOwnerApplicantIds',
            highCredit: 'highCredit',
            balance: 'balance',
            currency: 'currency',
            creditType: 'creditType',
            'payment.amount': 'payment.amount',
            'payment.frequency': 'payment.frequency',
            paymentMultiplier: 'paymentMultiplier',
            payoff: 'payoff',
            fromCreditBureau: 'fromCreditBureau',
            //
            // Mortgage servicing
            //
            approvedCombinedGDS: 'approvedCombinedGDS',
            approvedCombinedTDS: 'approvedCombinedTDS',
            lineOfCreditRepaymentOption: 'lineOfCreditRepaymentOption',
            feature: 'feature',
            investor: 'investor',
            insurer: 'insurer',
            bulkInsurance: 'bulkInsurance',
            bulkInsuranceCalculation: 'bulkInsuranceCalculation',
            bulkInsuranceOverride: 'bulkInsuranceOverride',
            actualPaymentAmount: 'actualPaymentAmount',
            qualifyingPaymentAmount: 'qualifyingPaymentAmount',
            cashbackPaidAmount: 'cashbackPaidAmount',
            nextPaymentDate: 'nextPaymentDate',
            blendedAmortization: 'blendedAmortization',
            premiumChargeAmount: 'premiumChargeAmount',
            premiumTaxAmount: 'premiumTaxAmount',
            insuranceCertificateNumber: 'insuranceCertificateNumber',
            commitmentIssuedDate: 'commitmentIssuedDate',
            nestoFees: 'nestoFees',
            dueToFCT: 'dueToFCT',
            //
            // Other Income
            //
            'income.frequency': 'income.frequency',
            'income.amount': 'income.amount',
            //
            // Owned Properties
            //
            hasMortgage: 'hasMortgage',
            'mortgage.balance': 'mortgage.balance',
            'mortgage.payment.amount': 'mortgage.payment.amount',
            'mortgage.payment.frequency': 'mortgage.payment.frequency',
            'mortgage.interestRate': 'mortgage.interestRate',
            'mortgage.interestRateType': 'mortgage.interestRateType',
            'mortgage.maturityDate': 'mortgage.maturityDate',
            'mortgage.mortgageType': 'mortgage.mortgageType',
            'mortgage.lender': 'mortgage.lender',
            'mortgage.termType': 'mortgage.termType',
            currentPurpose: 'currentPurpose',
            afterTransactionPurpose: 'afterTransactionPurpose',
            currentSaleStatus: 'currentSaleStatus',
            purchasePrice: 'purchasePrice',
            sellingDate: 'sellingDate',
            // 'type': 'type', // Duplicated
            estimatedPropertyValue: 'estimatedPropertyValue',
            'taxes.amount': 'taxes.amount',
            'taxes.year': 'taxes.year',
            taxesIncluded: 'taxesIncluded',
            'rentalIncome.amount': 'rentalIncome.amount',
            'rentalIncome.frequency': 'rentalIncome.frequency',
            rentalIncomeIncluded: 'rentalIncomeIncluded',
            'condoFees.amount': 'condoFees.amount',
            'condoFees.frequency': 'condoFees.frequency',
            condoFeesIncluded: 'condoFeesIncluded',
            'condoFees.heatingIncluded': 'condoFees.heatingIncluded',
            'heatingCost.amount': 'heatingCost.amount',
            'heatingCost.frequency': 'heatingCost.frequency',
            heatingCostIncluded: 'heatingCostIncluded',
            // Register Address
            //
            isCurrentAddress: 'isCurrentAddress',
            occupiedYears: 'occupiedYears',
            occupiedMonths: 'occupiedMonths',
            'address.countryCode': 'Country',
            'address.city': 'City',
            'address.postalCode': 'postalCode',
            'address.stateCode': 'stateCode',
            'address.unit': 'Unit',
            'address.streetNumber': 'streetNumber',
            'address.street': 'street',

            situation: 'situation',
            'rent.amount': 'rent.amount',
            'rent.frequency': 'rent.frequency',
            //
            // Subject Property
            //
            additionalFundAmount: 'additionalFundAmount',
            fundPurpose: 'fundPurpose',
            isFound: 'isFound',
            //
            // Subject Property Mortgage
            // `mortgages[0].` will be removed, code logic is taking last segment after the last `]`.
            //
            insuranceQuestionOriginallyInsured: 'insuranceQuestionOriginallyInsured',
            insuranceQuestionRefinanceOrRenewal: 'insuranceQuestionRefinanceOrRenewal',
            insuranceQuestionAddFundsOrIncreasedMortgageAmount: 'insuranceQuestionAddFundsOrIncreasedMortgageAmount',
            insuranceQuestionIncreasedAmortization: 'insuranceQuestionIncreasedAmortization',
            maturityDate: 'maturityDate',
            lastClosingDate: 'lastClosingDate',
            currentAmortizationMonths: 'currentAmortizationMonths',
            currentAmortizationYears: 'currentAmortizationYears',
            // balance: 'balance', // Duplicated
            interestRate: 'interestRate',
            interestRateType: 'interestRateType',
            lender: 'lender',
            mortgageType: 'mortgageType',
            termType: 'termType',
            //
            // Property Details
            //
            propertyType: 'propertyType',
            propertyStyle: 'propertyStyle',
            constructionType: 'constructionType',
            yearBuilt: 'yearBuilt',
            waterType: 'waterType',
            sewageType: 'sewageType',
            numberOfUnits: 'numberOfUnits',
            heatingType: 'heatingType',
            'livingSpace.amount': 'livingSpace.amount',
            'livingSpace.unit': 'livingSpace.unit',
            'lotSize.amount': 'lotSize.amount',
            'lotSize.unit': 'lotSize.unit',
            'garage.present': 'garage.present',
            'garage.type': 'garage.type',
            'garage.sizeType': 'garage.sizeType',
            //
            // Subject Property price
            //
            purchaseDate: 'purchaseDate',
            originalMortgageAmount: 'originalMortgageAmount',
            acceptanceDate: 'acceptanceDate',
            'schoolAndMunicipalTaxes.amount': 'schoolAndMunicipalTaxes.amount',
            'schoolAndMunicipalTaxes.year': 'schoolAndMunicipalTaxes.year',
            'schoolAndMunicipalTaxes.paidOnYourBehalf': 'schoolAndMunicipalTaxes.paidOnYourBehalf',
            'schoolAndMunicipalTaxes.taxHoldBackAmount': 'schoolAndMunicipalTaxes.taxHoldBackAmount',
            'coOwnershipFees.amount': 'coOwnershipFees.amount',
            'coOwnershipFees.frequency': 'coOwnershipFees.frequency',
            'coOwnershipFees.heatingIncluded': 'coOwnershipFees.heatingIncluded',
            purpose: 'purpose',
            //
            // Address
            //
            countryCode: 'countryCode',
            stateCode: 'stateCode',
            city: 'city',
            postalCode: 'postalCode',
            unit: 'unit',
            streetNumber: 'streetNumber',
            street: 'street',
            firstPaymentDate: 'firstPaymentDate',
            rateHold: 'rateHold.title',
            ratePrimeVariance: 'expandedRatePage.subsection.ratePrimeVariance',
            GDS: 'gdsLabel',
            TDS: 'tdsLabel',
        }?.[fieldName] || fieldName
    );
};

export const isFieldInvalid = (
    problems:
        | ApplicationProblemsGroupedWithApplicants
        | ApplicationProblemAugmented[],
    fieldName: string,
    compareFn: (...args: any[]) => boolean = () => false
) => {
    const checkFields = [fieldName];
    if (fieldName?.includes('formattedAddress')) {
        // remove `formattedAddress` in `fieldName`
        const fieldNamePrefix = fieldName.split('.').slice(0, -1).join('.');

        checkFields.push(
            `${fieldNamePrefix}.countryCode`,
            `${fieldNamePrefix}.stateCode`,
            `${fieldNamePrefix}.city`,
            `${fieldNamePrefix}.postalCode`,
            `${fieldNamePrefix}.unit`,
            `${fieldNamePrefix}.streetNumber`,
            `${fieldNamePrefix}.street`
        );
    }

    if (!Array.isArray(problems)) {
        let hasError = false;
        Object.keys(problems || []).forEach((applicantId) => {
            const problemFilter = problems?.[applicantId].filter((problem) => {
                return checkFields.includes(problem?.field?.name);
            });

            if (compareFn(problemFilter)) {
                hasError = true;
            }
        });

        return hasError;
    } else {
        const problemFilter = problems.filter(
            (problem) => problem?.field?.name === fieldName
        );

        return compareFn(problemFilter);
    }
};
