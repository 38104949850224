import { useReducer } from 'react';

import { RecoilState, useRecoilCallback } from 'recoil';

type State = {
    data: any;
    params: any;
    isRefreshing: boolean;
    error: any;
    hasValidationError: boolean;
};

const initialState: State = {
    data: null,
    params: null,
    isRefreshing: false,
    error: null,
    hasValidationError: false,
};

const ActionTypes = {
    ParametersUpdated: 'ParametersUpdated',
    ValidationFailed: 'ValidationFailed',
    FetchingFailed: 'FetchingFailed',
    FetchingSuccess: 'FetchingSuccess',
} as const;

type ActionType = typeof ActionTypes;

type Action =
    | { type: ActionType['ParametersUpdated']; params: any }
    | { type: ActionType['ValidationFailed'] }
    | { type: ActionType['FetchingFailed']; error: any }
    | { type: ActionType['FetchingSuccess'] };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.ParametersUpdated:
            return {
                ...state,
                params: action.params,
                isRefreshing: true,
            };
        case ActionTypes.ValidationFailed:
            return { ...state, isRefreshing: false, hasValidationError: true };
        case ActionTypes.FetchingFailed:
            return {
                ...state,
                isRefreshing: false,
                error: action.error,
                hasValidationError: false,
            };
        case ActionTypes.FetchingSuccess:
            return {
                ...state,
                isRefreshing: false,
                error: null,
                hasValidationError: false,
            };
        default:
            return { ...state };
    }
};

type UseRefreshRecoilStateOptions<ParamsType, StateType> = {
    recoilStateGetter: (params: ParamsType) => RecoilState<StateType>;
    fetcher: (params: ParamsType) => Promise<StateType>;
    validator?: (params: ParamsType) => Promise<boolean> | boolean;
    onError?: (error: any, params: ParamsType) => void;
    onValidationError?: (params: ParamsType) => void;
};

/**
 * typedef {Object} UseRefreshRecoilStateReturn
 * @property {function} refresh Function to call to refresh the Recoil state
 * @property {boolean} isRefreshing Boolean to determine if the Recoil state is being refreshed
 * @property {any} error Error object that is returned from the fetcher function
 */

/**
 * Hook to refresh a Recoil state based on the result of an async function
 *
 * @param options Options for the hook
 * @param options.recoilStateGetter function that returns a RecoilState of the state to be set
 * @param options.fetcher Async function to fetch data that will be set in the Recoil state
 * @param options.validator Validation function that returns a boolean to determine if the fetcher should be called and the state updated
 * @param options.onError Function to handle errors that occur during the fetcher call
 * @param options.onValidationError Function returned when the validator returns false
 *
 * @returns methods Object containing the methods and status of the refresh
 * @returns methods.refresh Function to call to refresh the Recoil state
 * @returns methods.isRefreshing Boolean to determine if the Recoil state is being refreshed
 * @returns methods.error Error object that is returned from the fetcher function
 * @returns methods.hasValidationError Boolean to determine if the validator returned false
 *
 * @example
 * const { refresh, isRefreshing, error } = useRefreshRecoilState({
 *       recoilStateGetter: (params) => myStateRecoilState(params.param1, params.param2),
 *       fetcher: async (params) => { return await myFetcher(params.param1, params.param2); },
 *       validator: (params) => { return params.param1 && params.param2; },
 *       onError: (error) => console.error(error),
 * });
 * const hasError = !!error;
 * const onSubmit = () => {
 *   // Then call the function to refresh the Recoil state
 *   refreshMyState({ param1: 'value1', param2: 'value2' });
 * }
 * if (isRefreshing) {
 *    return <Loading />;
 * }
 * if (hasError) {
 *   return <Error />;
 * }
 */
export const useRefreshRecoilState = <ParamsType, RecoilStateType>({
    recoilStateGetter,
    fetcher,
    validator,
    onError,
    onValidationError,
}: UseRefreshRecoilStateOptions<ParamsType, RecoilStateType>) => {
    const [{ params, isRefreshing, error, hasValidationError }, dispatch] =
        useReducer(reducer, initialState);

    const setRecoilValue = useRecoilCallback(
        ({ set }) =>
            async (params: ParamsType) => {
                if (validator) {
                    const validatorResult = await validator(params);

                    if (!validatorResult) {
                        if (onValidationError) {
                            onValidationError(params);
                        }

                        dispatch({ type: ActionTypes.ValidationFailed });

                        return;
                    }
                }

                try {
                    const data = await fetcher(params);

                    set(recoilStateGetter(params), data);

                    dispatch({
                        type: ActionTypes.FetchingSuccess,
                    });
                } catch (error) {
                    dispatch({ type: ActionTypes.FetchingFailed, error });

                    if (onError) {
                        onError(error, params);
                    }
                }
            },
        [
            params,
            validator,
            fetcher,
            onValidationError,
            onError,
            recoilStateGetter,
        ]
    );

    const updateParams = async (params: ParamsType) => {
        dispatch({ type: ActionTypes.ParametersUpdated, params });
        await setRecoilValue(params);
    };

    return {
        refresh: updateParams,
        isRefreshing,
        error,
        hasValidationError,
    };
};
