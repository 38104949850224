import { isCanadianSIN } from '@nestoca/validation';
import { isValid, parse } from 'date-fns';
import formatDate from 'date-fns/format';
import * as yup from 'yup';

import { convertUTCToLocalDate, numberNormalizer } from 'utils';
import { isEmpty } from 'utils';

import type { I18n } from '@lingui/core';
import type { TypeOf, Asserts } from 'yup/lib//util/types';
import type Lazy from 'yup/lib/Lazy';
import type { AnySchema } from 'yup/lib/schema';
import type { AnyObject, Maybe, Optionals } from 'yup/lib/types';

export const EMPTY_BACKEND_DATES = [
    '0001-01-01T00:00:00Z',
    '0001-01-01',
    '1000-01-01T00:00:00Z',
    '1000-01-01',
    '1900-12-31T00:00:00Z',
    '1900-12-31',
    '1-01-01T00:00:00Z',
    '1-01-01',
];

yup.addMethod<yup.StringSchema>(yup.string, 'nullAsEmpty', function () {
    return this.transform((value) => (value ? value : ''));
});

yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsUndefined', function () {
    return this.transform((value) => (value ? value : undefined));
});

yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsNull', function () {
    return this.transform((value) => (value ? value : null));
});

// Postal code regex (see https://regex101.com/r/Dthk4g/1)
// Canadian: H1H 1H1, H1H1H1, h0h0h0
// US: 90219
// US Extended: 90219-1121
const postalCodeRegex =
    /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9])|(?:[0-9]{5}(?:-[0-9]{4})?))$/i;

yup.addMethod<yup.StringSchema>(
    yup.string,
    'postalCode',
    (message = 'Invalid Postal Code') => {
        return yup.string().test(function (value?: string) {
            const { createError, path } = this;
            const regexMatch = value && value.match(postalCodeRegex);
            return regexMatch
                ? !!regexMatch.length
                : createError({
                      message,
                      path,
                  });
        });
    }
);
// North American phone regex (same as mask)
const phoneRegex = /(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
yup.addMethod(
    yup.string,
    'phone',
    function (message = 'Phone number is not valid') {
        return this.test('phone', message, (value) =>
            value ? phoneRegex.test(value) : true
        );
    }
);

// Canadian SIN
// @see `packages/office/src/utils/canadian-sin/is-canadian-sin.ts`
// for canadian SIN validation algorithm
// get fake number from
// https://www.myfakeinfo.com/nationalidno/get-canada-sin.php
// https://www.fakenamegenerator.com/gen-random-ca-ca.php
yup.addMethod(
    yup.string,
    'canadianSin',
    function canadianSin(message = 'Invalid Canadian SIN') {
        return this.test('is-canadian-sin', message, (value) => {
            if (!value) {
                return true;
            }

            return isCanadianSIN(value);
        });
    }
);

yup.addMethod<yup.DateSchema>(yup.date, 'emptyAsUndefined', function () {
    return this.transform((value, originalValue) =>
        String(originalValue)?.trim() ? value : undefined
    );
});

/**
 * Something (i.e. closing documents) backend is returning `0001-01-01T00:00:00Z`
 * instead of empty string `""` like what we have in the application.
 *
 * In the yup validation this invalid Date is converted to `1000-01-01T00:00:00Z`
 *
 * All the foloowing should be undefined because the field is let empty with an "invalid" empty date from BE
 */
yup.addMethod<yup.DateSchema>(yup.date, 'fixBackendIssue0001', function () {
    return this.transform((value, originalValue) => {
        const val = String(originalValue)?.trim();

        const isEmptyBackendDate = EMPTY_BACKEND_DATES.includes(val);

        return !isEmptyBackendDate ? value : undefined;
    });
});

yup.addMethod<yup.DateSchema>(yup.date, 'fixDateFormat', function () {
    return this.transform((value, originalValue) => {
        originalValue = String(originalValue)?.trim() as any;
        return convertUTCToLocalDate(originalValue);
    });
});

type YupDateFormatOptions = {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
};

/**
 * Please note that the format tokens differ from Moment.js and other libraries. See: https://git.io/fxCyr
 */
yup.addMethod<yup.DateSchema>(
    yup.date,
    'format',
    function (format: string, options?: YupDateFormatOptions) {
        return this.transform((value) =>
            value ? formatDate(value, format, options) : value
        );
    }
);

yup.addMethod<yup.DateSchema>(yup.date, 'transformDate', function () {
    return this.transform((_value: Date, originalValue: string | Date) => {
        const parsedDate =
            originalValue instanceof Date
                ? originalValue
                : parse(originalValue, 'yyyy-MM-dd', new Date());

        return isValid(parsedDate) ? parsedDate : null;
    });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsUndefined', function () {
    return this.transform((value, originalValue) =>
        String(originalValue)?.trim() ? value : undefined
    );
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsNull', function () {
    return this.transform((value) => (value || value === 0 ? value : null));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyStringAsNull', function () {
    return this.transform((value: number, originalValue: string) =>
        originalValue === '' ? null : value
    );
});

yup.addMethod<yup.NumberSchema>(yup.number, 'zeroAsUndefined', function () {
    return this.transform((value) => (value !== 0 ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsZero', function () {
    return this.transform((value: string, originalValue: string) =>
        isEmpty(originalValue) ? 0 : value
    );
});

yup.addMethod<yup.NumberSchema>(yup.number, 'fromMoney', function () {
    return this.transform((value: number, originalValue: number | string) => {
        // originalValue = `$ 0` | `01` | `1` | `0`
        if (isNaN(value)) {
            return numberNormalizer(originalValue);
        }
        return value;
    });
});

yup.addMethod<yup.NumberSchema>(yup.number, 'isValueNaN', function () {
    return this.transform((value: number) => {
        // originalValue = `$ 0` | `01` | `1` | `0`
        return isNaN(value) ? null : value;
    });
});

yup.addMethod<yup.BooleanSchema>(yup.boolean, 'emptyAsFalse', function () {
    return this.transform((value: string, originalValue: string) =>
        originalValue === undefined ||
        originalValue === null ||
        isEmpty(originalValue)
            ? false
            : value
    );
});

yup.addMethod<yup.BooleanSchema>(yup.boolean, 'emptyAsUndefined', function () {
    return this.transform((value: string, originalValue: string) =>
        originalValue === undefined ||
        originalValue === null ||
        isEmpty(originalValue)
            ? undefined
            : value
    );
});

yup.addMethod<yup.BooleanSchema>(yup.boolean, 'emptyAsNull', function () {
    return this.transform((currentValue: boolean, originalValue: string) => {
        return originalValue === '' ? null : currentValue;
    });
});

yup.addMethod(
    yup.array,
    'atMostOne',
    function ({
        message,
        predicate,
    }: {
        message: string;
        predicate: (item: any) => boolean;
    }) {
        return this.test('atMostOne', message, function (list) {
            // If there are 2+ elements after filtering, we know atMostOne must be false.
            return list.filter(predicate).length < 2;
        });
    }
);

yup.addMethod(
    yup.array,
    'atLeastOne',
    function ({
        message,
        predicate,
    }: {
        message: string;
        predicate: (item: any) => boolean;
    }) {
        return this.test('atLeastOne', message, function (list) {
            // If there are 1+ elements after filtering, we know atLeastOne must be true.
            return list.filter(predicate).length >= 1;
        });
    }
);

/**
 * This method is used to make a field required based on a list of fields.
 * Checks the `$requiredFields` property in the form's context to see if the field is in the list.
 * If it is, the field is required, otherwise it is optional.
 */
yup.addMethod(
    yup.mixed,
    'requiredInArray',
    function (
        fieldName: string,
        i18n: I18n,
        message?: string,
        values?: Record<string, any>
    ) {
        return this.when(['$requiredFields'], (requiredFields, schema) =>
            requiredFields?.includes(fieldName)
                ? schema
                      .required(
                          i18n._({
                              id: message || 'error.fieldRequired',
                              values,
                          })
                      )
                      .typeError(
                          i18n._({
                              id: message || 'error.fieldRequired',
                              values,
                          })
                      )
                : schema.optional().nullable()
        );
    }
);

declare module 'yup' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface BaseSchema<TCast, TContext, TOutput> {
        requiredInArray(
            fieldName: string,
            i18n: I18n,
            message?: string,
            values?: Record<string, any>
        ): this;
    }

    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        emptyAsUndefined(): StringSchema<TType, TContext>;
        emptyAsNull(): StringSchema<TType, TContext>;
        nullAsEmpty(): StringSchema<TType, TContext>;
        postalCode(message?: string): StringSchema<TType, TContext>;
        phone(message?: string): StringSchema<TType, TContext>;
        canadianSin(message?: string): StringSchema<TType, TContext>;
    }

    interface NumberSchema<
        TType extends Maybe<number> = number | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        emptyAsUndefined(): NumberSchema<TType, TContext>;
        emptyAsNull(): NumberSchema<TType, TContext>;
        emptyStringAsNull(): NumberSchema<TType, TContext>;
        zeroAsUndefined(): NumberSchema<TType, TContext>;
        emptyAsZero(): NumberSchema<TType, TContext>;
        fromMoney(): NumberSchema<TType, TContext>;
        isValueNaN(): NumberSchema<TType, TContext>;
    }

    interface DateSchema<
        TType extends Maybe<Date>,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        /**
         * Something (i.e. closing documents) backend is returning `0001-01-01T00:00:00Z`
         * instead of empty string `""` like what we have in the application.
         *
         * In the yup validation this invalid Date is converted to `1000-01-01T00:00:00Z`
         *
         * All the foloowing should be undefined because the field is let empty with an "invalid" empty date from BE
         */
        fixBackendIssue0001(): DateSchema<TType, TContext>;
        fixDateFormat(): DateSchema<TType, TContext>;
        emptyAsUndefined(): DateSchema<TType, TContext>;
        transformDate(): DateSchema<TType, TContext>;
        format(
            format: string,
            options?: YupDateFormatOptions
        ): DateSchema<TType, TContext>;
    }

    interface BooleanSchema<
        TType extends Maybe<boolean>,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        emptyAsFalse(): BooleanSchema<TType, TContext>;
        emptyAsUndefined(): BooleanSchema<TType, TContext>;
        emptyAsNull(): BooleanSchema<TType, TContext>;
    }

    interface ArraySchema<
        T extends AnySchema | Lazy<any, any>,
        C extends AnyObject = AnyObject,
        TIn extends Maybe<TypeOf<T>[]> = TypeOf<T>[] | undefined,
        TOut extends Maybe<Asserts<T>[]> = Asserts<T>[] | Optionals<TIn>,
    > extends yup.BaseSchema<TIn, C, TOut> {
        atMostOne(arg: {
            message?: string;
            predicate: (item: any) => boolean;
        }): ArraySchema<T, C, TOut>;
        atLeastOne(arg: {
            message?: string;
            predicate: (item: any) => boolean;
        }): ArraySchema<T, C, TOut>;
    }
}

export default yup;
