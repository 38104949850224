import Big from 'big.js';
import numeral from 'numeral';
import { groupBy } from 'ramda';

import { round } from './math';

export * from './applicant';
export * from './application';
export * from './address';
export * from './calculateFileSize';
export * from './truncateText';
export * from './normalizer';
export * from './fns';
export * from './validators';
export * from './document';
export * from './mortgage-servicing';
export * from './use-resize-observer';

export const noOp = () => {
    return;
};

export const isEmptyString = (value?: string) => value?.length === 0;

export const isEmptyArray = (arr?: any[]) => arr?.length === 0;

export const isEmptyObject = (obj?: any) => {
    for (const prop in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
};

export const isNull = (value?: any) => value === null;

export const isUndefined = (value?: any) => value === undefined;

export const isEmptyNumber = (value?: number) =>
    value !== undefined ? isNaN(value) : false;

export const isEmpty = (value: any) => {
    return (
        isNull(value) ||
        isUndefined(value) ||
        (typeof value === 'string' && isEmptyString(value)) ||
        (typeof value === 'number' && isNaN(value)) ||
        (Array.isArray(value) && isEmptyArray(value)) ||
        (typeof value === 'object' && isEmptyObject(value))
    );
};

/**
 * Check if a value is null or undefined
 * @param value - The value to check
 */
export const isNullOrUndefined = (value: any) =>
    isNull(value) || isUndefined(value);

export const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export const debounce = (callback: any, waitMs: number, immediate = false) => {
    let timeout: any = null;
    return (...args: any) => {
        const callNow = immediate && !timeout;
        const next = () => callback(...args);
        clearTimeout(timeout);
        timeout = setTimeout(next, waitMs);
        if (callNow) {
            next();
        }
    };
};

export const convertUTCToLocalDate = (date) => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
    );
    return date;
};

export const getByPath = <T = any>(
    obj: any,
    path: string,
    defaultValue?: T
): T => {
    const result = path
        .split(/[,[\].]+?/)
        .filter(Boolean)
        .reduce(
            (result, key) => (isNullOrUndefined(result) ? result : result[key]),
            obj
        );

    return isUndefined(result) || result === obj
        ? obj[path] || defaultValue
        : result;
};

export const getDownPaymentPercentage = (
    propertyValue?: number,
    downPayment?: number
): number => {
    if (!propertyValue || !downPayment) return 0;
    const percentage = (downPayment / propertyValue) * 100;
    return +percentage.toFixed(2);
};

export const byType = groupBy(
    (item: Record<string, any> & { type: string }) => item.type
);

export const getDownPaymentAmount = (
    percentage: number,
    propertyValue: number
) => {
    if (!propertyValue || propertyValue <= 0 || percentage <= 0) return 0;

    const amount = (percentage * propertyValue) / 100;

    return round(amount, 2, Big.roundHalfUp);
};

export const getDownPaymentPercentageValue = (downPayment, propertyValue) => {
    if (propertyValue <= 0 || downPayment <= 0) return 0;

    const percentage = (downPayment / propertyValue) * 100;

    return percentage;
};

type ValidateDownPaymentParams = {
    propertyValue: number;
    downPayment: number;
    isRental: boolean;
    enableNewInsuredMarketCap: boolean;
};

export const validateDownPayment = ({
    propertyValue,
    downPayment,
    isRental,
    enableNewInsuredMarketCap,
}: ValidateDownPaymentParams) => {
    if (propertyValue <= 0 || downPayment <= 0) return false;

    const insuredMarketCap = enableNewInsuredMarketCap ? 1_500_000 : 1_000_000;

    const downPaymentPercentage = getDownPaymentPercentageValue(
        downPayment,
        propertyValue
    );
    const isRentalProperty = isRental;
    const downPaymentRemainder = (propertyValue - 500_000) * 0.1;
    const isAboveInsuredMktCap = propertyValue >= insuredMarketCap;
    const isMoreThan500k = propertyValue > 500_000;
    const is500kAndLess = !isMoreThan500k;

    if (isRentalProperty && downPaymentPercentage < 20) {
        return false;
    }

    // Above insured market cap
    if (isAboveInsuredMktCap && downPaymentPercentage < 20) {
        return false;
    }

    // less than equal 500k
    if (is500kAndLess && downPaymentPercentage < 5) {
        return false;
    }

    // more than 500k
    if (isMoreThan500k && 25_000 + downPaymentRemainder > downPayment) {
        return false;
    }

    return true;
};

export const getMinimumDownPayment = (
    propertyValue: number,
    isRental: boolean,
    enableNewInsuredMarketCap: boolean
): { percentage: number; value: number } => {
    const insuredMarketCap = enableNewInsuredMarketCap ? 1_500_000 : 1_000_000;

    const minDownPayment = 5;
    let minDownPaymentPercentage = minDownPayment;

    if (!propertyValue) {
        return {
            percentage: minDownPaymentPercentage,
            value: 0,
        };
    }

    if (isRental) {
        minDownPaymentPercentage = 20;

        return {
            percentage: minDownPaymentPercentage,
            value: getDownPaymentAmount(
                minDownPaymentPercentage,
                propertyValue
            ),
        };
    }

    // Over insured market cap
    if (propertyValue >= insuredMarketCap) {
        minDownPaymentPercentage = 20;
    }

    const downPaymentRemainder = (propertyValue - 500000) * 0.1;

    // more than 500k
    if (propertyValue > 500_000 && propertyValue < insuredMarketCap) {
        minDownPaymentPercentage = getDownPaymentPercentageValue(
            25000 + downPaymentRemainder,
            propertyValue
        );
    }

    // less than 500k
    if (propertyValue <= 500_000) minDownPaymentPercentage = minDownPayment;

    return {
        percentage: minDownPaymentPercentage,
        value: getDownPaymentAmount(minDownPaymentPercentage, propertyValue),
    };
};

export const getAdditionalAmountMaxValue = (
    propertyValue: number,
    mortgageBalance: number
) => {
    return numeral(propertyValue * 0.8 - mortgageBalance).value();
};

export const insertAtIndex = <T>(
    index: number,
    element: T,
    elements: T[]
): T[] => {
    return [...elements.slice(0, index), element, ...elements.slice(index + 1)];
};
