import { ApplicationState } from 'types/application';

export enum ProblemType {
    applicants = 'applicants',
    application = 'application',
    assets = 'assets',
    downPayment = 'downPayment',
    employments = 'employments',
    flags = 'flags',
    helocDetails = 'helocDetails',
    incomeOthers = 'incomeOthers',
    liabilities = 'liabilities',
    mortgageDetails = 'mortgageDetails',
    product = 'product',
    properties = 'properties',
    registeredAddresses = 'registeredAddresses',
    subjectProperty = 'subjectProperty',
}

export const PROBLEMS_SECTION_TITLES = (i18n) => ({
    [ProblemType.application]: i18n._(`validation.application`),
    [ProblemType.applicants]: i18n._(`validation.applicants`),
    [ProblemType.registeredAddresses]: i18n._(`validation.registeredAddresses`),
    [ProblemType.employments]: i18n._(`validation.employments`),
    [ProblemType.incomeOthers]: i18n._(`validation.incomeOthers`),
    [ProblemType.assets]: i18n._(`validation.assets`),
    [ProblemType.properties]: i18n._(`validation.properties`),
    [ProblemType.liabilities]: i18n._(`validation.liabilities`),
    [ProblemType.mortgageDetails]: i18n._(`validation.mortgageDetails`),
    [ProblemType.subjectProperty]: i18n._(`validation.subjectProperty`),
    [ProblemType.downPayment]: i18n._(`validation.downPayment`),
    [ProblemType.helocDetails]: i18n._(`validation.helocDetails`),
});

export type Problems = { section: ProblemType; totalProblems: number };

export const HIDDEN_SECTIONS = ['flags', 'TODOS', 'product'];

export const ACTIVE_APPLICATION_PROBLEM_STATES: ApplicationState[] = [
    'CREATED',
    'SUBMITTED',
    'UNDER_REVISION',
    'REVIEWED',
];
