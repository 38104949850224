import type { Applicant, ApplicantInfo } from 'types/applicant';

export const adaptApplicantInfo = (applicant: Applicant): ApplicantInfo => ({
    amlScore: applicant.amlScore,
    applicantId: applicant.applicantId,
    salutation: applicant.salutation,
    firstName: applicant.firstName,
    lastName: applicant.lastName,
    email: applicant.email,
    dateOfBirth: applicant.dateOfBirth,
    phone: applicant.phone,
    maritalStatus: applicant.maritalStatus,
    numberOfDependents: applicant.numberOfDependents,
    relationToMainApplicant: applicant.relationToMainApplicant,
    firstTimeHomeBuyer: applicant.firstTimeHomeBuyer,
    primaryBankingInstitution: applicant.primaryBankingInstitution,
    primaryBankingInstitutionOther: applicant.primaryBankingInstitutionOther,
    creditScoreQuality: applicant.creditScoreQuality,
    propertiesSpecified: applicant.propertiesSpecified,
    otherIncomesSpecified: applicant.otherIncomesSpecified,
    covid19Impacted: applicant.covid19Impacted,
    covid19ImpactDescription: applicant.covid19ImpactDescription,
    hasConsumerProposalOrBankruptcyLast5yrs:
        applicant.hasConsumerProposalOrBankruptcyLast5yrs,
    permissions: applicant.permissions,
    financialInstitutionAccountNumber:
        applicant.financialInstitutionAccountNumber,
    financialInstitutionBankID: applicant.financialInstitutionBankID,
    financialInstitutionTransitNumber:
        applicant.financialInstitutionTransitNumber,
    newToCanada: applicant.newToCanada,
    residencyStatus: applicant.residencyStatus,
    isNestoEmployee: applicant.isNestoEmployee,
    isDigital: applicant.isDigital,
    divorcedOrSeparated: applicant.divorcedOrSeparated,
    hasOwnerOccupancy: applicant.hasOwnerOccupancy,
    guarantor: applicant.guarantor,
    mpiInsurerResponse: applicant.mpiInsurerResponse,
    identityId: applicant.identityId,
    privacyMode: applicant.privacyMode,
    sin: applicant.sin,
    partner: applicant.partner,
    verbalConsent: applicant.verbalConsent,
});
